import { Form, Formik, FormikHelpers } from 'formik';
import Cookies from 'js-cookie';
import { Button, FieldInput } from 'litto-lib/components/common';
import React from 'react';
import * as Yup from 'yup';

import Alert from 'components/shared/Alert';
import { usePasswordResetMutation } from 'services/auth/password-reset';

interface IForgotPasswordFormData {
	email: string;
}
export interface IForgotPasswordFormProps {
	className?: string;
	onClose?: () => void;
	onSuccess?: () => void;
}

export const ForgotPasswordForm: React.FC<IForgotPasswordFormProps> = ({
	className,
	onClose,
	onSuccess
}) => {
	const { mutateAsync: submitPasswordResetRequest, isSuccess } =
		usePasswordResetMutation({
			onError: () => {},
			onSuccess: data => {
				onSuccess?.();
				Cookies.set('token', data.token, { expires: 365, path: '/' });
			}
		});

	const onLogIn = async (
		formData: IForgotPasswordFormData,
		formikHelpers: FormikHelpers<IForgotPasswordFormData>
	) => {
		const { setSubmitting } = formikHelpers;
		setSubmitting(true);

		try {
			await submitPasswordResetRequest(formData);
			// show({
			// 	headerText: 'Success',
			// 	message: '',
			// 	type: 'success'
			// });
			setSubmitting(false);
		} catch (err) {
			setSubmitting(false);
			return Promise.reject(err);
		}
	};

	if (isSuccess) {
		return (
			<div className={`${className || ''} bg-white flex flex-col gap-4`}>
				<Alert type="success">
					If the email that you entered is in our database, you will receive an
					email containing steps on how to change the password.
				</Alert>
				<Button onClick={() => onClose?.()}>Close</Button>
			</div>
		);
	}

	return (
		<div className={`${className || ''} bg-white`}>
			<p className="text-md mb-4 text-gray-500">
				Enter your e-mail to receive password reset link.
			</p>
			<Formik
				initialValues={{
					email: ''
				}}
				validationSchema={Yup.object().shape({
					email: Yup.string()
						.email('E-mail is not valid.')
						.required('Field is required')
				})}
				onSubmit={onLogIn}
			>
				{props => {
					const { errors, submitForm, submitCount, isSubmitting } = props;
					return (
						<Form
							className="flex flex-col"
							noValidate={true}
							onKeyDown={e => {
								if (e.key === 'Enter') {
									submitForm();
								}
							}}
						>
							<FieldInput
							data-testid="forgot-password_email-input"
								className="mb-5"
								name="email"
								type="email"
								label={'E-mail address'}
								error={(submitCount && errors.email) || ''}
							/>
							<Button
								className="mt-5"
								text="Submit"
								disabled={isSubmitting}
								loading={isSubmitting}
							/>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};
