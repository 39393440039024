import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axios from 'axios';

export interface IPasswordResetData {
	email: string;
}



export const resetPassword = async (passwordResetData: IPasswordResetData) => {
	return await axios
		.post(`/api/v2/admin/reset-password-requests`, passwordResetData)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch((err) => {
			return Promise.reject(err);
		});
};

type usePasswordResetMutationOptions = Omit<
	UseMutationOptions<any, unknown, IPasswordResetData, unknown>,
	'mutationFn'
>;

export function usePasswordResetMutation(options?: usePasswordResetMutationOptions) {
	return useMutation(resetPassword, {
		...options,
		onError: e => {
			return Promise.reject(e);
		}
	});
}
