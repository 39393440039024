import { AxiosError } from 'axios';
import { Form, Formik, FormikHelpers } from 'formik';
import Cookies from 'js-cookie';
import { Button, FieldInput, Modal } from 'litto-lib/components/common';
import { useRouter } from 'next/router';
import React from 'react';
import * as Yup from 'yup';

import { ForgotPasswordForm } from './ForgotPasswordForm';
import { useLoginMutation } from 'services/auth/login';

interface ILoginFormData {
	email: string;
	password: string;
}
export interface ILoginFormProps {
	className?: string;

	onSuccess?: () => void;
}

export const LoginForm: React.FC<ILoginFormProps> = ({
	className,

	onSuccess
}) => {
	const router = useRouter();
	const {
		mutateAsync: login,
		error,
		isError
	} = useLoginMutation({
		onError: () => {},
		onSuccess: data => {
			onSuccess?.();
			Cookies.set('token', data.token, { expires: 365, path: '/' });
		}
	});

	const onLogIn = async (
		formData: ILoginFormData,
		formikHelpers: FormikHelpers<ILoginFormData>
	) => {
		const { setSubmitting } = formikHelpers;
		setSubmitting(true);

		try {
			await login(formData);
			router.push('/');
			setSubmitting(false);
		} catch (err) {
			setSubmitting(false);
		}
	};

	const [showForgotPasswordModal, setShowForgotPasswordModal] =
		React.useState(false);

	return (
		<div className={`${className || ''} bg-white ${isError ? 'mt-12': 'mt-19'}`}>
			{isError && (
				<div className=" bg-[#FBE6E680] border border-red text-gray-500 font-bold text-sm px-5 py-3 rounded mb-5">
					{(error as AxiosError<any>)?.response?.data.message ||
						'Something went wrong.'}
				</div>
			)}
			<Formik
				initialValues={{
					email: '',
					password: ''
				}}
				validationSchema={Yup.object().shape({
					email: Yup.string()
						.email('E-mail is not valid.')
						.required('Field is required'),
					password: Yup.string()
						// .min(8, 'Password must be at least 8 characters long')
						.required('Field is required')
				})}
				onSubmit={onLogIn}
			>
				{props => {
					const { errors, submitForm, submitCount, isSubmitting } = props;
					return (
						<Form
							className="flex flex-col "
							noValidate={true}
							onKeyDown={e => {
								if (e.key === 'Enter') {
									submitForm();
								}
							}}
						>
							<FieldInput
								className="mb-5"
								name="email"
								type="email"
								label={'E-mail address'}
								placeholder="Type e-mail address"
								error={(submitCount && errors.email) || ''}
							/>
							<FieldInput
								className="mb-1"
								name="password"
								type="password"
								label="Password"
								placeholder="Type password"
								error={(submitCount && errors.password) || ''}
							/>
							<Button
								className="mt-5"
								text="Login"
								disabled={isSubmitting}
								loading={isSubmitting}
							/>
							<Button
								variant="link"
								type="button"
								className="text-lg !font-normal mt-9 inline-block text-black hover:underline text-center"
								onClick={() => setShowForgotPasswordModal(true)}
							>
								<p className="text-center">Forgot my password?</p>
							</Button>
						</Form>
					);
				}}
			</Formik>
			<Modal
				headerText="Reset your password"
				isOpen={showForgotPasswordModal}
				onRequestClose={() => setShowForgotPasswordModal(false)}
				className="!max-w-lg"
			>
				<ForgotPasswordForm onClose={() => setShowForgotPasswordModal(false)} />
			</Modal>
		</div>
	);
};
