import { SvgLogo } from 'litto-lib/assets';
import React from 'react';

import { LoginForm } from 'components/auth/LoginForm';

const LoginPage: React.FC = () => {
	return (
		<main className="h-screen container max-w-md flex flex-col justify-center  ">
			<div>
				<SvgLogo className="text-primary-500 mb-3 " />
				<p className="text-sm">
					Please login to access your Litto personal dashboard.
				</p>
			</div>
			<LoginForm />
		</main>
	);
};

export default LoginPage;
