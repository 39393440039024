import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axios from 'axios';

export interface IUserLoginData {
	email: string;
	password: string;
}

export interface IUserLoginResponse {
    token: string
}

export const login = async (userLoginData: IUserLoginData) => {
	return await axios
		.post<IUserLoginResponse>(`/api/v2/admin/authentication-token`, userLoginData)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch((err: IUserLoginResponse) => {
			return Promise.reject(err);
		});
};

type useLoginMutationOptions = Omit<
	UseMutationOptions<IUserLoginResponse, unknown, IUserLoginData, unknown>,
	'mutationFn'
>;

export function useLoginMutation(options?: useLoginMutationOptions) {
	return useMutation(login, {
		...options,
		onError: e => {
			return Promise.reject(e);
		}
	});
}
